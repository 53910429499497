import React, { Component } from "react";
import Sketch from "react-p5";

var myImage, img_data, fileCount, tileSize, brushSize;

class SquaredPortrait extends Component {
  getImageURI = props => {
    img_data = this.props.state.imageURI;
    fileCount = this.props.state.fileCount;

    if (fileCount >= 10000) {
      tileSize = 6;
    } else if (fileCount <= 10000 && fileCount > 5000) {
      tileSize = 12;
    } else if (fileCount <= 5000 && fileCount > 100) {
      tileSize = 24;
    } else {
      tileSize = 42;
    }
  };

  setup = (p5, canvasParentRef) => {
    p5.createCanvas(500, 500).parent(canvasParentRef);
    p5.pixelDensity(3);
    p5.background(200);
    p5.frameRate(1);
    p5.noLoop();
  };

  preload = p5 => {
    this.getImageURI();
    myImage = p5.loadImage(img_data);
  };

  draw = p5 => {
    myImage.loadPixels();
    //p5.rectMode(p5.CORNER);
    p5.background(200);
    p5.fill(1);
    p5.noStroke();

    for (var y = 0; y < 500; y = y + tileSize) {
      for (var x = 0; x < 500; x = x + tileSize) {
        var col = myImage.get(x, y);
        var rgb = col[0] + col[1] + col[2];
        //map(value, start1, stop1, start2, stop2, [withinBounds])
        brushSize = p5.map(rgb, 0, 765, tileSize, 0);
        p5.rect(x, y, brushSize, brushSize);
        //p5.circle(x, y, brushSize);
      }
    }
  };

  render() {
    return <Sketch setup={this.setup} preload={this.preload} draw={this.draw} />;
  }
}

export default SquaredPortrait;
