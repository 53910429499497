import React from "react";

function About() {
  return (
    <div className="item" id="about">
      <div className="inner">
        <div className="col chapter">
          <div className="title">About</div>
          <div className="number">
            Pt
            <br />
            05.
          </div>
        </div>
        <div className="col">
          <p className="question">Why make this?</p>
          <p>
            Let’s face it, Data Privacy is something you rarely think about, and in one sentence, that is it. Data
            Aeternum exists to educate and spread awareness about Data Privacy so that you can make conscious and
            informed decisions in your day-to-day internet activities. It does this by holistically collecting,
            curating, and humanizing information and technical knowledge that you need to know, while simultaneously
            empowering you with actionable tools and resources to reduce your data footprint.
          </p>
          <p>
            We’re so often so concerned with the next big innovation, that we don’t stop to think about how it was even
            possible. Today’s technological innovations, especially in machine learning and artificial intelligence, are
            possible because of our data. It’s possible because we’re tracked at every single click, tap, and scroll,
            because our default settings leave us woefully exposed to the watchful eye, and because there’s no
            governmental oversight over what’s off-limits. If we don’t educate ourselves, educate others, and demand
            change, tomorrow’s technological innovations might come at an even greater cost.
          </p>
        </div>
        <div className="col">
          <p className="question">Who made this?</p>
          <p>
            Data Aeternum is a thesis project that is designed, written, and coded by{" "}
            <a href="https://helloshivam.com" target="_blank" rel="noopener noreferrer">
              Shivam Sinha
            </a>{" "}
            , as part of the BFA Communication Design program at the Parsons School of Design. It’s designed for a
            universal at-risk audience of young adults, who aren’t aware of the dangers of profiling, and technology
            illiterate adults and seniors, who might be unaware of these practices. Though it’s designed as a thesis
            project, there’s a strong intention for this project to live outside the scope of it, functioning as a
            real-world tool that people can universally have access to, and benefit from.
          </p>
          <p>
            Shivam is a designer and creative coder, currently based in New York City, mixing his passion for design and
            technology to make tools, products, and experiences that seek to alleviate and pragmatically improve
            products and services for all. You can learn more about Shivam,{" "}
            <a href="https://helloshivam.com" target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </p>
          <p>
            Data Aeternum is an open-source project available to everyone free of cost. Please consider donating and
            contributing to this project so that it may continue to be available for everyone to utilize.
          </p>
          {/* <div
            className="imageContainer"
            style={{
              backgroundImage:
                'url("https://res.cloudinary.com/shivamsinha/image/upload/v1583882259/thesis-2020/magnifying.jpg")',
            }}
          ></div> */}
        </div>
      </div>
    </div>
  );
}

export default About;
