import React from "react";

function Resources() {
  return (
    <div className="item" id="resources">
      <div className="inner-sm">
        <div className="col resources">
          <div className="pdf">
            <div
              className="pdfCoverContainer"
              style={{
                backgroundImage:
                  "url(https://res.cloudinary.com/shivamsinha/image/upload/v1586470930/thesis-2020/Handbook_Cover.jpg)",
              }}
            ></div>
            <p className="desc">
              The Data Aeternum Handbook provides essential context to the themes and topics that this website touches
              on. <br /> Download the PDF or buy a physical book.
            </p>
            <div className="buttons">
              <a href="/files/data-aeternum-handbook.pdf" target="_blank" rel="noopener noreferrer">
                <div className="btn download">↓ Download PDF</div>
              </a>
              <a tooltip="Coming Soon!" target="_blank" rel="noopener noreferrer">
                <div className="btn buy">Buy Print ↗</div>
              </a>
            </div>
          </div>
          <div className="resourceLinks">
            <p className="question">Further Resources</p>
            <p>
              Here's a list of further readings and tools you can use to further your understanding about Data Privacy
              and Data Collection:
            </p>
            <ol>
              <li>
                <a
                  href="http://www.nytimes.com/interactive/2019/opinion/internet-privacy-project.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Privacy Project
                </a>
              </li>
              <li>
                <a href="https://ethical.net/resources/" target="_blank" rel="noopener noreferrer">
                  Ethical Alternatives &amp; Resources
                </a>
              </li>
              <li>
                <a href="http://amiunique.org/fp" target="_blank" rel="noopener noreferrer">
                  Browser Fingerprint Testing Tool
                </a>
              </li>
              <li>
                <a href="https://www.websitecarbon.com/" target="_blank" rel="noopener noreferrer">
                  Website Carbon Calculator
                </a>
              </li>
              <li>
                <a href="http://monitor.firefox.com/security-tips" target="_blank" rel="noopener noreferrer">
                  Security Tips to protect yourself
                </a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resources;
