import React from "react";
import { isMobile } from "react-device-detect";

function Why() {
  return (
    <div className="item" id="why">
      <div className="inner">
        <div className="col chapter">
          <div className="title">Why</div>
          <div className="number">
            Pt
            <br />
            02.
          </div>
        </div>
        <div className="col">
          <p className="question">Why should I care? I’ve got nothing to hide.</p>
          <p>
            There are two sets of reasons to care about your privacy even if you’ve got nothing to hide—ideological
            reasons and practical reasons. Ideologically, privacy is a right that we haven't always had. Just like the
            right to freedom of speech, generations before ours have fought for our right to privacy. Now, it’s a human
            right.
          </p>
          <p>
            Having nothing to hide is not true nor realistic. Don’t confuse privacy with secrecy. Everyone knows what
            you do in the bathroom, yet you still close the door. That’s because you want privacy, not secrecy. Just
            like you have a passcode for your phone, and passwords for your email because you don’t want people reading
            your personal messages.
          </p>
        </div>
        {!isMobile ? (
          <div className="col">
            <p>
              Practically, especially, information in the wrong hands becomes dangerous. You might be okay with
              governments or security agencies having your private information. You might even trust Google or Facebook.
              But what if these get hacked and your information now falls in the wrong hands? What would your
              information make you vulnerable to? (See{" "}
              <a
                href="http://money.cnn.com/2016/09/22/technology/yahoo-data-breach/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Yahoo
              </a>{" "}
              or{" "}
              <a href="http://fortune.com/2015/08/26/ashley-madison-hack/" target="_blank" rel="noopener noreferrer">
                Ashley Madison
              </a>
              ). You also can’t predict the future. Right now you may not have a lot to risk, but what about 30 or 40
              years from now? If Sony’s hacking has told us anything, is that your private information has an impact on
              your life. Amy Pascal, co-chairman of the company, lost her job because of it.
            </p>
            <p>
              Your private life out of context becomes a weapon. We’ve all joked about things we consider sensitive.
              But, among friends, it’s something we all do. In fact, our behavior changes depending on the people we’re
              with. Someone could easily find something offensive you said in a group chat that you have with your
              closest friends. Because they’re your friends and it was a joke or a sarcastic remark. But take it out of
              context and it is no longer a joke.
            </p>
            <p>
              Your information has value. A company like Facebook or Google allows you to upload unlimited data to their
              servers, for free. What’s their business model? How do they make so much money? They sell your info to
              advertising companies and{" "}
              <a
                href="https://www.fastcompany.com/90310803/here-are-the-data-brokers-quietly-buying-and-selling-your-personal-information"
                target="_blank"
                rel="noopener noreferrer"
              >
                data brokers
              </a>
              . But they never asked you if you wanted to sell your information. If someone asked you, in person, 100
              questions about your personal life with the intent of selling the information, would you answer them?
              Probably not, right? But you let this happen every time you use a service that makes money selling your
              info.
            </p>
          </div>
        ) : (
          <>
            <div className="col">
              <p>
                Practically, especially, information in the wrong hands becomes dangerous. You might be okay with
                governments or security agencies having your private information. You might even trust Google or
                Facebook. But what if these get hacked and your information now falls in the wrong hands? What would
                your information make you vulnerable to? (See{" "}
                <a
                  href="http://money.cnn.com/2016/09/22/technology/yahoo-data-breach/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Yahoo
                </a>{" "}
                or{" "}
                <a href="http://fortune.com/2015/08/26/ashley-madison-hack/" target="_blank" rel="noopener noreferrer">
                  Ashley Madison
                </a>
                ). You also can’t predict the future. Right now you may not have a lot to risk, but what about 30 or 40
                years from now? If Sony’s hacking has told us anything, is that your private information has an impact
                on your life. Amy Pascal, co-chairman of the company, lost her job because of it.
              </p>
            </div>
            <div className="col">
              <p>
                Your private life out of context becomes a weapon. We’ve all joked about things we consider sensitive.
                But, among friends, it’s something we all do. In fact, our behavior changes depending on the people
                we’re with. Someone could easily find something offensive you said in a group chat that you have with
                your closest friends. Because they’re your friends and it was a joke or a sarcastic remark. But take it
                out of context and it is no longer a joke.
              </p>
              <p>
                Your information has value. A company like Facebook or Google allows you to upload unlimited data to
                their servers, for free. What’s their business model? How do they make so much money? They sell your
                info to advertising companies and{" "}
                <a
                  href="https://www.fastcompany.com/90310803/here-are-the-data-brokers-quietly-buying-and-selling-your-personal-information"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  data brokers
                </a>
                . But they never asked you if you wanted to sell your information. If someone asked you, in person, 100
                questions about your personal life with the intent of selling the information, would you answer them?
                Probably not, right? But you let this happen every time you use a service that makes money selling your
                info.
              </p>
            </div>
          </>
        )}
        <div className="col">
          <p>
            With the 2020 Covid-19 pandemic forcing people to stay home, work from home, and now spend more time on
            their digital devices than ever before, it becomes imperative to talk about why privacy matters. As schools
            and businesses transitioned to conduct classes and meetings on the popular video conferencing app, Zoom,
            little did they know that their data was also being{" "}
            <a
              href="https://nypost.com/2020/03/31/lawsuit-claims-zoom-illegally-shared-user-data-with-facebook/"
              target="_blank"
              rel="noopener noreferrer"
            >
              shared with Facebook
            </a>
            , regardless of whether they were Facebook users or not. When caught publicly, Zoom quickly reversed the
            code to stop sharing data with Facebook. As we increasingly become more and more dependent on these
            technologies, we must ask ourselves about the tradeoffs we’re making by using these services. It is why it’s
            important to care and demand oversight change on a industry-wide level.
          </p>
          <div
            className="imageContainer"
            style={{
              backgroundImage:
                'url("https://res.cloudinary.com/shivamsinha/image/upload/v1586465210/thesis-2020/wired-zoom-provacy.jpg")',
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default Why;
