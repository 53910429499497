import React, { Component } from "react";
import $ from "jquery";
import { isMobile } from "react-device-detect";

class Share extends Component {
  componentDidMount() {
    (function () {
      var DISPLAY_WIDTH = $(".shareCoverContainer").innerWidth(),
        DISPLAY_HEIGHT = $(".shareCoverContainer").innerHeight(),
        DISPLAY_DURATION = 10;

      var mouse = { x: 0, y: 0 },
        container,
        canvas,
        context,
        startTime,
        eyes;

      function initialize() {
        container = document.querySelector(".shareCoverContainer");
        canvas = document.querySelector(".shareCoverContainer>canvas");

        if (canvas) {
          canvas.width = DISPLAY_WIDTH;
          canvas.height = DISPLAY_HEIGHT;

          context = canvas.getContext("2d");

          window.addEventListener(
            "mousemove",
            function (event) {
              mouse.x = event.clientX;
              mouse.y = event.clientY;
            },
            false
          );

          eyes = [new Eye(canvas, 0.5, 0.5, 1.3, 0.1)];

          startTime = Date.now();

          animate();
        }
      }

      function animate() {
        // The number of seconds that have passed since initialization
        var seconds = (Date.now() - startTime) / 1000;

        // Out with the old ...
        context.clearRect(0, 0, DISPLAY_WIDTH, DISPLAY_HEIGHT);

        // ... in with the new
        for (var i = 0, len = eyes.length; i < len; i++) {
          var eye = eyes[i];

          if (seconds > eye.activationTime * DISPLAY_DURATION) {
            eye.activate();
          }

          eye.update(mouse);
        }

        //requestAnimFrame(animate);
        window.requestAnimationFrame(animate);
      }

      setTimeout(initialize, 1);
    })();

    function Eye(canvas, x, y, scale, time) {
      this.canvas = canvas;
      this.context = this.canvas.getContext("2d");

      // The time at which this eye will come alive
      this.activationTime = time;

      // The speed at which the iris follows the mouse
      this.irisSpeed = 0.01 + (Math.random() * 0.2) / scale;

      // The speed at which the eye opens and closes
      this.blinkSpeed = 0.2 + Math.random() * 0.2;
      this.blinkInterval = 5000 + 5000 * Math.random();

      // Timestamp of the last blink
      this.blinkTime = Date.now();

      this.scale = scale;
      this.size = 70 * scale;

      this.x = x * canvas.width;
      this.y = y * canvas.height + this.size * 0.15;

      this.iris = {
        x: this.x,
        y: this.y,
        size: this.size * 0.25,
      };

      this.pupil = {
        width: 2 * scale,
        height: this.iris.size * 0.75,
      };

      this.exposure = {
        top: 0.1 + Math.random() * 0.3,
        bottom: 0.75,
        current: 0,
        target: 1,
      };

      // Affects the amount of inner shadow
      this.tiredness = 0.5 - this.exposure.top + 0.1;

      this.isActive = false;

      this.activate = function () {
        this.isActive = true;
      };

      this.update = function (mouse) {
        if (this.isActive === true) {
          this.render(mouse);
        }
      };

      this.render = function (mouse) {
        var time = Date.now();

        if (this.exposure.current < 0.012) {
          this.exposure.target = 1;
        } else if (time - this.blinkTime > this.blinkInterval) {
          this.exposure.target = 0;
          this.blinkTime = time;
        }

        this.exposure.current += (this.exposure.target - this.exposure.current) * this.blinkSpeed;

        // Eye left/right
        var el = { x: this.x - this.size * 0.8, y: this.y - this.size * 0.1 };
        var er = { x: this.x + this.size * 0.8, y: this.y - this.size * 0.1 };

        // Eye top/bottom
        var et = {
          x: this.x,
          y: this.y - this.size * (0.5 + this.exposure.top * this.exposure.current),
        };
        var eb = {
          x: this.x,
          y: this.y - this.size * (0.45 - this.exposure.bottom * this.exposure.current),
        };

        // Eye inner shadow top
        var eit = {
          x: this.x,
          y: this.y - this.size * (0.5 + (0.5 - this.tiredness) * this.exposure.current),
        };

        // Eye iris
        var ei = { x: this.x, y: this.y - this.iris.size };

        // Offset the iris depending on mouse position
        var eio = {
          x: (mouse.x - ei.x * 15) / (window.innerWidth + ei.x * 150),
          y: mouse.y / window.innerHeight,
        };

        // Apply the iris offset
        ei.x += eio.x * 15 * Math.max(1, this.scale * 12);
        ei.y += eio.y * 7 * Math.max(1, this.scale * 5);

        this.iris.x += (ei.x - this.iris.x) * this.irisSpeed;
        this.iris.y += (ei.y - this.iris.y) * this.irisSpeed;

        // Eye fill drawing
        this.context.fillStyle = "rgba(255,255,255,1.0)";
        this.context.strokeStyle = "rgba(100,100,100,1.0)";
        this.context.beginPath();
        this.context.lineWidth = 3;
        this.context.lineJoin = "round";
        this.context.moveTo(el.x, el.y);
        this.context.quadraticCurveTo(et.x, et.y, er.x, er.y);
        this.context.quadraticCurveTo(eb.x, eb.y, el.x, el.y);
        this.context.closePath();
        this.context.stroke();
        this.context.fill();

        // Iris
        this.context.save();
        this.context.globalCompositeOperation = "source-atop";
        this.context.translate(this.iris.x * 0.1, 0);
        this.context.scale(0.9, 1);
        this.context.strokeStyle = "rgba(25,25,25,0.125)";
        this.context.fillStyle = "rgba(25,25,25,0.125)";
        this.context.lineWidth = 2;
        this.context.beginPath();
        this.context.arc(this.iris.x, this.iris.y, this.iris.size * 1.25, 0, Math.PI * 2, true);
        this.context.fill();
        //this.context.stroke();
        this.context.restore();

        // Iris inner
        this.context.save();
        // this.context.shadowColor = "rgba(255,255,255,0.5)";
        // this.context.shadowOffsetX = 0;
        // this.context.shadowOffsetY = 0;
        // this.context.shadowBlur = 2 * this.scale;
        this.context.globalCompositeOperation = "source-atop";
        this.context.translate(this.iris.x * 0.1, 0);
        this.context.scale(0.9, 1);
        this.context.fillStyle = "rgba(0,0,0,1)";
        this.context.beginPath();
        this.context.arc(this.iris.x, this.iris.y, this.iris.size * 0.75, 0, Math.PI * 2, true);
        this.context.fill();
        this.context.restore();

        // Pupil
        this.context.save();
        this.context.globalCompositeOperation = "source-atop";
        this.context.fillStyle = "rgba(255,255,255,0.9)";
        this.context.beginPath();
        this.context.arc(this.iris.x, this.iris.y, this.iris.size * 0.25, 0, 2 * Math.PI, false);
        this.context.fill();
        this.context.restore();

        this.context.save();
        this.context.shadowColor = "rgba(0,0,0,0.8)";
        this.context.shadowOffsetX = 0;
        this.context.shadowOffsetY = 0;
        this.context.shadowBlur = 10;

        // Eye top inner shadow
        this.context.fillStyle = "rgba(120,120,120,0.2)";
        this.context.beginPath();
        this.context.moveTo(el.x, el.y);
        this.context.quadraticCurveTo(et.x, et.y, er.x, er.y);
        this.context.quadraticCurveTo(eit.x, eit.y, el.x, el.y);
        this.context.closePath();
        this.context.fill();

        this.context.restore();
      };
    }
  }

  render(props) {
    return (
      <div className="item" id="share">
        <div className="inner-sm">
          <div className="col social">
            <div className="share">
              <div className="shareCoverContainer">
                <canvas></canvas>
              </div>
              <p className="desc">Spread the word about Data Privacy</p>
              <div className="buttons">
                <a
                  href="https://www.facebook.com/sharer/sharer.php?u=http://data-aeternum.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="btn facebook">
                    <img alt="Facebook" src="https://cdn.jsdelivr.net/npm/simple-icons@latest/icons/facebook.svg" />
                  </div>
                </a>
                <a
                  href="https://twitter.com/home?status=http://data-aeternum.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="btn twitter">
                    <img alt="Twitter" src="https://cdn.jsdelivr.net/npm/simple-icons@latest/icons/twitter.svg" />
                  </div>
                </a>
                <a
                  href="http://www.reddit.com/submit?url=https://data-aeternum.com/&title=Learn%20about%20your%20Data%20Privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="btn reddit">
                    <img alt="Reddit" src="https://cdn.jsdelivr.net/npm/simple-icons@latest/icons/reddit.svg" />
                  </div>
                </a>
                <a href="https://www.instagram.com/hello_shiv/" target="_blank" rel="noopener noreferrer">
                  <div className="btn instagram">
                    <img alt="Instagram" src="https://cdn.jsdelivr.net/npm/simple-icons@latest/icons/instagram.svg" />
                  </div>
                </a>
              </div>
            </div>
            <div className="footerLinks">
              <div className="buttons">
                <a
                  href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=E8765LUXLUCAS&currency_code=USD&source=url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="btn donate">Donate</div>
                </a>
                {!isMobile ? (
                  <a
                    href="mailto:shivam@newschool.edu?subject=Data Aeternum Contribution"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="btn">Contribute</div>
                  </a>
                ) : null}
                <a
                  href="mailto:shivam@newschool.edu?subject=Data Aeternum Error Report"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="btn error">Report Error</div>
                </a>
                <a href="#hero" onClick={(e) => this.props.handleMenuClick(e, "#hero")}>
                  <div className="btn">Back to Front</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Share;
