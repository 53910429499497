import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import $ from "jquery";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  backgroundColor: "#C8C8C8",
  color: "#191919",
  outline: "none",
  transition: "all .25s ease-in-out",
};

const activeStyle = {
  backgroundColor: "#cecece",
  color: "#e5e5e5",
};

const acceptStyle = {
  backgroundColor: "#009b6e",
  color: "#e5e5e5",
};

const rejectStyle = {
  backgroundColor: "#f53743",
  color: "#e5e5e5",
};

function DragDrop(props) {
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    //accept: "image/*",
    onDrop: (acceptedFiles) => {
      props.handleLoading();
      //console.log(acceptedFiles);
      let validPaths = acceptedFiles.filter(function (e) {
        return (
          e.path.includes("Google") ||
          e.path.includes("Facebook") ||
          e.path.includes("Apple") ||
          e.path.includes("Instagram") ||
          e.path.includes("Snapchat") ||
          e.path.includes("Mircosoft") ||
          e.path.includes("Twitter")
        );
      });

      let validFileTypes = acceptedFiles.filter(function (e) {
        return e.type.includes("text/javascript") || e.type.includes("text/json") || e.type.includes("image/");
      });

      //console.log(validFileTypes, validPaths);
      var BreakException = {};
      if (validPaths.length >= 1 && validFileTypes.length >= 100) {
        // $(".dnd>.container>div>p")
        //   .html("Paths and File Types are valid")
        //   .removeClass("error warning")
        //   .addClass("accepted");
        //Read actual files
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();

          reader.onabort = () => console.log("file reading was aborted");
          reader.onerror = () => console.log("file reading failed");
          // reader.onload = () => {
          //   const binaryStr = reader.result;
          //   console.log(binaryStr);
          // };
          // reader.readAsArrayBuffer(file);
          // Make sure `file.name` matches our extensions criteria
          if (/\.(jpe?g|png|gif)$/i.test(file.name) && file.name.includes("ProfilePhoto")) {
            reader.addEventListener(
              "load",
              function () {
                var image = new Image();
                image.title = file.name;
                image.src = this.result;
                // $(".dnd>.profileImageContainer")
                //   .append(image)
                //   .addClass("active");
                //console.log(image.src);
                props.handleFileState(acceptedFiles.length, this.result);
                setTimeout(function () {
                  props.handleValidFiles();
                  //throw BreakException;
                }, 2000);
              },
              false
            );

            reader.readAsDataURL(file);
          }
        });
      } else if (validPaths.length >= 1 && validFileTypes.length < 100) {
        //console.log("WARN!");
        props.handleLoading();
        $(".dnd>.container>div>p")
          .html("WARN: There doesn't seem to be enough data here.")
          .removeClass("accepted error")
          .addClass("warning");
      } else {
        //console.log("ERROR!");
        props.handleLoading();
        $(".dnd>.container>div>p")
          .html("ERR: These files don't seem like your data.")
          .removeClass("accepted warning")
          .addClass("error");
      }
    },
    onAbort: () => {
      $(".dnd>.container>div>p").html("File processing was aborted").removeClass("error warning").addClass("warning");
    },
    onError: () => {
      $(".dnd>.container>div>p")
        .html("There was an error processing your files.")
        .removeClass("accepted warning")
        .addClass("error");
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  );

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {props.state.loading ? (
          <div className="loading">
            {/* SVG Here */}
            <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <g transform="rotate(0 50 50)">
                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.9166666666666666s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(30 50 50)">
                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.8333333333333334s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(60 50 50)">
                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.75s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(90 50 50)">
                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.6666666666666666s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(120 50 50)">
                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.5833333333333334s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(150 50 50)">
                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.5s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(180 50 50)">
                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.4166666666666667s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(210 50 50)">
                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.3333333333333333s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(240 50 50)">
                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.25s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(270 50 50)">
                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.16666666666666666s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(300 50 50)">
                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.08333333333333333s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(330 50 50)">
                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="0s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
            </svg>
          </div>
        ) : (
          <p className="dragInstructions">Drag &amp; drop your data here, or click to archive</p>
        )}
      </div>
    </section>
  );
}

export default DragDrop;
