import React, { Component } from "react";
import $ from "jquery";
import HorizontalScroll from "react-scroll-horizontal";
import Draggable from "react-draggable";
import PWAPrompt from "react-ios-pwa-prompt";

//Styles
import "./assets/App.scss";

//Components
import Hero from "./components/Hero";
import Portrait from "./components/Portrait";
import What from "./components/What";
import Why from "./components/Why";
import How from "./components/How";
import Resources from "./components/Resources";
import About from "./components/About";
import Share from "./components/Share";

//Components

class App extends Component {
  constructor() {
    super();
    this.state = {
      deltaX: 0,
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  componentDidMount() {
    $(".scroll-horizontal").css({ overflow: "visible" });
  }

  handleDrag = (e, data) => {
    this.setState({
      deltaX: data.deltaX * 2,
    });
  };

  handleMenuClick = (e, id) => {
    //console.log("Clicked");
    e.preventDefault();
    var distance = $(id).offset().left * -1;
    this.setState({
      deltaX: distance,
    });
  };

  render() {
    return (
      <div className="App">
        <PWAPrompt
          copyBody={
            "This website is designed as a Progressive Web App (PWA). To use it as it's intended, please add it to your homescreen to use it in fullscreen and while offline."
          }
          permanentlyHideOnDismiss={false}
          timesToShow={3}
          delay={2000}
        />
        <div className="scroll-view">
          <div className="scroll-wrap">
            <div className="scroll-items">
              <div className="menuLinks">
                <a href="#what" onClick={(e) => this.handleMenuClick(e, "#what")}>
                  01 &mdash; What
                </a>
                <a href="#why" onClick={(e) => this.handleMenuClick(e, "#why")}>
                  02 &mdash; Why
                </a>
                <a href="#portrait" onClick={(e) => this.handleMenuClick(e, "#portrait")}>
                  03 &mdash; Generate
                </a>
                <a href="#how" onClick={(e) => this.handleMenuClick(e, "#how")}>
                  04 &mdash; How
                </a>
                <a href="#about" onClick={(e) => this.handleMenuClick(e, "#about")}>
                  05 &mdash; About
                </a>
              </div>
              <Draggable axis="x" onDrag={this.handleDrag}>
                <div>
                  <HorizontalScroll reverseScroll={true} animValues={this.state.deltaX}>
                    <Hero handleMenuClick={this.handleMenuClick.bind(this)} />
                    <What />
                    <Why />
                    <Portrait />
                    <How />
                    <Resources />
                    <About />
                    <Share handleMenuClick={this.handleMenuClick.bind(this)} />
                  </HorizontalScroll>
                </div>
              </Draggable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
