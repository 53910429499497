import React from "react";

function iconItem(props) {
  return (
    <a href={props.icon.url} target="_blank" rel="noopener noreferrer" className="actionItem">
      <div className="iconContainer" style={{ backgroundImage: `url(${props.icon.imageUrl})` }}>
        {/* <img src={props.icon.imageUrl} alt={props.icon.name + " Logo"} /> */}
      </div>
      <div className="label">{props.icon.name}</div>
    </a>
  );
}

export default iconItem;
