import React from "react";

function What() {
  return (
    <div className="item" id="what">
      <div className="inner">
        <div className="col chapter">
          <div className="title">What</div>
          <div className="number">
            Pt
            <br />
            01.
          </div>
        </div>
        <div className="col">
          <p className="question">What is Data Privacy?</p>
          <p>
            Data privacy relates to how a piece of information — or data — should be handled based on its relative
            importance. For instance, you likely wouldn’t mind sharing your name with a stranger in the process of
            introducing yourself, but there’s other information you wouldn’t share, like your bank account information
            or passwords.
          </p>
          <p>
            In our current digital age, a lot of information and metrics are collected about us, than we realize.
            Companies today, in a push for innovation and to maintain an edge over thier competitors, analyze, mine, and
            track virtually everything we do.
          </p>
        </div>
        <div className="col">
          <p>
            These metrics can be as simple as our names, emails, and date of births, or as personal as our likes, wants,
            interests, and desires. Whether you actively realize this or not, chances are your platforms know you better
            than your friends or even close family members.
          </p>
          <p>
            The convoluted and obscure nature of data collection and tracking has enabled companies to amass data about
            everyone without any real oversight. With data now a more valuable commodity than gold, it's now more
            important than ever to take a strong stance on data privacy.
          </p>
          <div
            className="imageContainer"
            style={{
              backgroundImage:
                'url("https://res.cloudinary.com/shivamsinha/image/upload/v1583882259/thesis-2020/magnifying.jpg")'
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default What;
