const todosData = [
  {
    id: 1,
    completed: false,
    title: "Review your social media privacy settings:",
    desc: `<p>
            Over the years social media companies are able to gather staggering amounts of data about
            you, your interests, who you talk to, where you go, what you buy, and so much more.
          </p>
          <p>
            If you‘re not ready to give up social media quite yet, you should take the time to review
            your security and privacy settings. Visualizing the amount of information that social media
            companies know about you may be enough to curb that unhealthy newsfeed obsession.
          </p>`,
    apps: [
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/jumbo.png",
        name: "Jumbo",
        url: "https://www.jumboprivacy.com/",
      },
    ],
  },
  {
    id: 2,
    completed: false,
    title: "Use a privacy-first search engine:",
    desc: `<p>
            You should use a search engine that protects you from tracking, fingerprinting, and unwanted
            advertisements. DuckDuckGo is a privacy-first search engine that does not store your search
            history, has strict location and personalization permissions, and publishes regular content
            teaching people how to be safer on the web. Ecosia is a similar service with a focus on privacy, but also plants trees with your searches.
          </p>`,
    apps: [
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/duckduckgo.jpg",
        name: "DuckDuckGo",
        url: "https://duckduckgo.com/",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/ecosia.png",
        name: "Ecosia",
        url: "https://ecosia.org/",
      },
    ],
  },
  {
    id: 3,
    completed: false,
    title: "Use a VPN:",
    desc: `<p>
            A VPN, or virtual private network, is a useful tool to secure an internet connection. It
            guarantees that data you are sending and receiving is encrypted, preventing people from
            snooping on your traffic.
          </p>
          <p>
            You should use a VPN provider that you trust to not harvest and re-sell your data. The best
            VPNs often charge a monthly subscription - this is a good thing because it means their
            business model is not reliant upon reselling your data to advertisers.
          </p>`,
    apps: [
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/ivpn.jpg",
        name: "IVPN",
        url: "https://www.ivpn.net/",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/encrypt-me.jpg",
        name: "Encrypt.me",
        url: "https://encrypt.me/",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888918/thesis-2020/icons/nordvpn.jpg",
        name: "NordVPN",
        url: "https://nordvpn.com/",
      },
      {
        imageUrl:
          "https://res.cloudinary.com/shivamsinha/image/upload/v1583888918/thesis-2020/icons/privateinternetaccess.jpg",
        name: "PIA",
        url: "https://www.privateinternetaccess.com/",
      },
    ],
  },
  {
    id: 4,
    completed: false,
    title: "Use a privacy-first email provider:",
    desc: `<p>In the case of some popular email providers, including Google and Yahoo, they collect data from your email inbox. Both companies use algorithms to scan the content of your email for certain keywords with the goal of providing advertising targeted toward your interests.</p>
          <p>
            You should use an email provider that doesn’t read your email or gather data about your
            conversations to target you with ads.
          </p>`,
    apps: [
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/fastmail.jpg",
        name: "Fastmail",
        url: "https://www.fastmail.com/",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888918/thesis-2020/icons/protonmail.jpg",
        name: "ProtonMail.me",
        url: "https://protonmail.com//",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583893206/thesis-2020/icons/simplelogin.png",
        name: "SimpleLogin",
        url: "https://simplelogin.io/",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/mailfence.png",
        name: "MailFence",
        url: "https://mailfence.com/",
      },
    ],
  },
  {
    id: 5,
    completed: false,
    title: "Use encrypted messaging apps:",
    desc: `<p>
            You should be using a secure, end-to-end encrypted messaging service. End-to-end encryption
            ensures that only you and your intended recipient are able to view messages. Your messages
            will appear scrambled (and will be nearly-impossible to unscramble) to anyone else,
            including app developers and ISPs.
          </p>`,
    apps: [
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/imessage.png",
        name: "iMessage",
        url: "https://support.apple.com/explore/messages",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888918/thesis-2020/icons/whatsapp.jpg",
        name: "WhatsApp",
        url: "https://www.whatsapp.com/",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888918/thesis-2020/icons/signal.jpg",
        name: "Signal",
        url: "https://www.signal.org/",
      },
    ],
  },
  {
    id: 6,
    completed: false,
    title: "Use a password manager:",
    desc: `<p>
            A password manager helps you create a unique password for each online service you use.
            Having a unique password ensures that if one service you use is hacked, the compromised
            password won’t allow access to all of your other accounts.
          </p>
          <p>
            At the very minimum, you should have a unique password for high-value accounts like iCloud, emails, and bank accounts. You can use <a href="https://www.idownloadblog.com/2018/10/25/password-auditing-iphone-ipad-mac/" target="_blank" rel="noopener noreferer">password auditing</a> to determine vulnerabilities.
          </p>`,
    apps: [
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888916/thesis-2020/icons/1password.jpg",
        name: "1Password",
        url: "https://1password.com/",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/lastpass.jpg",
        name: "LastPass",
        url: "https://www.lastpass.com/",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/dashlane.jpg",
        name: "DashLane",
        url: "https://www.dashlane.com/",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/icloud.png",
        name: "iCloud Keychain",
        url: "https://www.icloud.com/",
      },
    ],
  },
  {
    id: 7,
    completed: false,
    title: "Use a privacy-first web browser:",
    desc: `<p>
            You should use a web browser that protects you from tracking, fingerprinting, and unwanted
            advertisements.
          </p>
          <p>
            Modern browsers have made it simple to transfer your bookmarks and preferences in order to
            reduce switching pains.
          </p>`,
    apps: [
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/brave.jpg",
        name: "Brave",
        url: "https://brave.com/",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888918/thesis-2020/icons/safari.jpg",
        name: "Safari",
        url: "https://www.apple.com/safari/",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/firefox.jpg",
        name: "Firefox",
        url: "https://www.mozilla.org/en-US/firefox/",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888918/thesis-2020/icons/vivaldi.png",
        name: "Vivaldi",
        url: "https://www.vivaldi.com/",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888918/thesis-2020/icons/tor.png",
        name: "Tor",
        url: "https://www.torproject.org/",
      },
    ],
  },
  {
    id: 8,
    completed: false,
    title: "Review location, camera, and other sensitive device permissions:",
    desc: `<p>
            As a vital step to the devices you use, you should review all applications that have access to your photos, camera, location, and
            microphone. Ensure that you trust apps with sensitive permissions.
          </p>
          <p>
            Make sure to disable Wifi Scanning and Bluetooth Scanning for apps you don't trust. In fact, very limited apps should have permissions to do so. Learn more about WiFi and Bluetooth scanning <a href="https://medium.com/@ExplainAndroid/how-to-disable-wifi-and-bluetooth-scanning-1bcd483dffeb" target="_blank" rel="noopener noreferer">here</a>.
          </p>`,
    apps: [
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888916/thesis-2020/icons/ios.png",
        name: "iOS",
        url: "https://support.apple.com/en-us/HT203033",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/macos.png",
        name: "macOS",
        url: "https://support.apple.com/guide/mac-help/change-privacy-preferences-on-mac-mh32356/mac",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888916/thesis-2020/icons/android.png",
        name: "Android",
        url: "https://www.wired.co.uk/article/android-privacy-settings-oreo-security",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888918/thesis-2020/icons/windows10.png",
        name: "Windows",
        url: "https://www.wired.com/story/windows-10-privacy-settings/",
      },
      {
        imageUrl: "https://res.cloudinary.com/shivamsinha/image/upload/v1583888917/thesis-2020/icons/linux.png",
        name: "Linux",
        url: "https://spreadprivacy.com/linux-privacy-tips/",
      },
    ],
  },
];

export default todosData;
