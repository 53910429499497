import React, { Component } from "react";
import Sketch from "react-p5";

var subX = 0;
var subY = 0;
var dotXstart = 320; // Dot start X postion
var dotYstart = 240; // Dot start Y postion
var rndMin = -10; // random MIN
var rndMax = 10; // random MAX
var nDots = 100; // number of dots
var maxBrushSize = 3; //max brush size
var minBrushSize = 0; // min brush size
//var vScale = 2;
var myImage, fileCount, img_data;

class DottedPortrait extends Component {
  // www.lomz.net - 2019.
  // https://stihilus.github.io/lomz/generated_p5js_portraits.html

  // Press R to reload canvas
  // For save image: Right click > Save image as

  getImageURI = props => {
    img_data = this.props.state.imageURI;
    fileCount = this.props.state.fileCount;

    if (fileCount >= 5000) {
      nDots = fileCount + fileCount * 5;
    } else {
      nDots = fileCount + fileCount * 10;
    }
  };

  setup = (p5, canvasParentRef) => {
    p5.createCanvas(500, 500).parent(canvasParentRef);
    p5.pixelDensity(3);
    //frameRate(5);
    // video = createCapture(VIDEO);
    // video.size(width / vScale, height / vScale);
    p5.background(200);
    p5.noStroke();
    p5.fill(0, 0, 0);
    p5.noLoop();
  };

  preload = p5 => {
    this.getImageURI();
    myImage = p5.loadImage(img_data);
  };

  draw = p5 => {
    myImage.loadPixels();

    for (var i = 0; i < nDots; i++) {
      var dotXPosition = dotXstart + subX;
      var dotYPosition = dotXstart + subY;

      subX += p5.random(rndMin, rndMax);
      subY += p5.random(rndMin, rndMax);

      var col = myImage.get(dotXPosition, dotYPosition);
      //console.log(col);

      var rgb = col[0] + col[1] + col[2];
      //console.log(rgb);

      var brushSize = p5.map(rgb, 0, 765, maxBrushSize, minBrushSize);

      // fill(col[0],col[1],col[2]);
      p5.fill(50);
      p5.circle(dotXPosition, dotYPosition, brushSize);
      //console.log(subX);

      if (subX > 320) {
        subX = 0;
        subY = 0;
      }

      if (subX < -320) {
        subX = 0;
        subY = 0;
      }

      if (subY > 240) {
        subX = 0;
        subY = 0;
      }

      if (subX < -440) {
        subX = 0;
        subY = 0;
      }
    }
  };

  keyReleased = p5 => {
    if (p5.key === "r" || p5.key === "R") p5.background(200);
  };

  render() {
    return <Sketch setup={this.setup} preload={this.preload} draw={this.draw} keyReleased={this.keyReleased} />;
  }
}

export default DottedPortrait;
