import React, { Component } from "react";
import { Transition } from "react-transition-group";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import * as jsPDF from "jspdf";
import { isMobile, isTablet } from "react-device-detect";

//Components
import DragDrop from "./DragDrop";
import SquaredPortrait from "./SquaredPortrait";
import DottedPortrait from "./DottedPortrait";
import LinePortrait from "./LinePortrait";

class Portrait extends Component {
  constructor() {
    super();
    this.state = {
      fileCount: 0,
      imageURI: "",
      loading: false,
      validFiles: false,
      dotted: false,
      square: false,
      line: false,
    };
    this.handleDownload = this.handleDownload.bind(this);
    this._handleDotted = this._handleDotted.bind(this);
    this._handleToDotted = this._handleToDotted.bind(this);
    this._handleSquare = this._handleSquare.bind(this);
    this._handleToSquare = this._handleToSquare.bind(this);
    this._handleLine = this._handleLine.bind(this);
    this._handleToLine = this._handleToLine.bind(this);
    this._handleTranToDotted = this._handleTranToDotted.bind(this);
  }

  handleDownload = () => {
    const input = document.querySelector(".dataCard");
    domtoimage.toPng(input, { bgcolor: "#e5e5e5" }).then(function (dataUrl) {
      const pdf = new jsPDF({
        orientation: "landscape",
        format: [input.offsetHeight, input.offsetWidth],
      });
      const imgProps = pdf.getImageProperties(dataUrl);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("Data-Card.pdf");
    });
  };

  handleLoading = () => {
    this.setState({
      ...this.state,
      loading: !this.state.loading,
    });
  };

  handleFileState = (count, image) => {
    this.setState({
      ...this.state,
      fileCount: count,
      imageURI: image,
    });
  };

  handleValidFiles = () => {
    //console.log(this.state);
    this.setState({
      ...this.state,
      validFiles: true,
    });
  };

  _handleDotted = () => {
    //console.log(this.state);
    this.setState({
      ...this.state,
      dotted: !this.state.dotted,
    });
  };

  _handleToSquare = () => {
    this.setState({
      ...this.state,
      square: !this.state.square,
    });
  };

  _handleSquare = () => {
    //console.log(this.state);
    this.setState({
      ...this.state,
      square: !this.state.square,
    });
  };

  _handleToLine = () => {
    this.setState({
      ...this.state,
      line: !this.state.line,
    });
  };

  _handleLine = () => {
    //console.log(this.state);
    this.setState({
      ...this.state,
      line: !this.state.line,
    });
  };

  _handleToDotted = () => {
    this.setState({
      ...this.state,
      dotted: !this.state.dotted,
    });
  };

  _handleTranToDotted = () => {
    setTimeout(
      function () {
        this.setState({
          ...this.state,
          dotted: !this.state.dotted,
        });
      }.bind(this),
      3000
    );
  };

  render() {
    const duration = 500;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var time = today.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
    var timestamp = `${mm}/${dd} ${time}`;

    return (
      <div className="item" id="portrait">
        <div className="inner">
          <div className="col chapter">
            <div className="title">Generate</div>
            <div className="number">
              Pt
              <br />
              03.
            </div>
          </div>
          <div className="col">
            <p className="question">Generate your Data Portrait &rarr;</p>
            <p>
              Your data portrait helps you understand your data footprint at a glance. It works by mapping your data to
              your profile image. The more visible you are in your portrait, the more data companies have on you.
              Alternatively, the less visible you are in your portrait, the more you are hidden on the internet.
            </p>
            <p>
              To get started, request your data from the different platforms you use. This can take up to 2-3 days. Once
              you have your data, return to this page and drop the data here. Be sure to follow the instructions in
              preparing your data for analysis. Your data card will then be generated, which contains your portrait, as
              well as some more info.
            </p>
            <p>
              <span className="disclaimer">Disclaimer:</span> Your data isn't uploaded anywhere. All processes are done
              locally, on your machine. If you feel more comfortable, you can turn off your internet before you upload
              your data.
            </p>
          </div>
          <div className="col portrait">
            <Transition in={this.state.validFiles} onEntered={this._handleTranToDotted} timeout={2000}>
              {(state) => (
                <div className={`split loading loading-${state}`}>
                  <div className="top">
                    <div className="line">Data</div>
                    <div className="line">+ Privacy</div>
                  </div>
                  <div className="bottom">
                    <div className="caption">
                      {this.state.validFiles ? (
                        <p className="left">&darr; Click to Change Portrait Type</p>
                      ) : (
                        <p>&darr; Drop your Data to Generate your Data Card</p>
                      )}
                    </div>
                    <div className="dnd">
                      <div className={`mobileBlocker ${isMobile || isTablet ? `blocked` : null}`}>
                        <div>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="90.5px"
                            height="90.5px"
                            viewBox="0 0 90.5 90.5"
                          >
                            <defs></defs>
                            <path
                              d="M45.3,0C20.3,0,0,20.3,0,45.3s20.3,45.3,45.3,45.3s45.3-20.3,45.3-45.3C90.5,20.3,70.2,0,45.3,0z M10,45.3
              	C10,25.8,25.7,10,45.2,10c7.6,0,15.1,2.5,21.2,7.1L17,66.4C12.5,60.3,10,52.9,10,45.3z M45.3,80.5c-7.6,0-15-2.5-21.1-7.1l49.3-49.3
              	c11.7,15.6,8.5,37.7-7,49.3C60.3,78,52.9,80.5,45.3,80.5z"
                            />
                          </svg>
                          <p>This feature is not available on mobile. Please access this site on desktop.</p>
                        </div>
                      </div>
                      <DragDrop
                        state={this.state}
                        handleLoading={this.handleLoading.bind(this)}
                        handleFileState={this.handleFileState.bind(this)}
                        handleValidFiles={this.handleValidFiles.bind(this)}
                      />
                      <div tooltip="Download Data Card" onClick={this.handleDownload} className="download">
                        <p>&darr;</p>
                      </div>
                      <div tooltip="Instructions" className="help">
                        <p>?</p>
                      </div>
                      <div className="dataCard">
                        <div className="portraitContainer">
                          <Transition
                            in={this.state.validFiles && this.state.dotted}
                            unmountOnExit
                            onExited={this._handleToSquare}
                            timeout={duration}
                          >
                            {(state) => (
                              <div className={`fade fade-${state}`} onClick={this._handleDotted}>
                                <DottedPortrait state={this.state} />
                              </div>
                            )}
                          </Transition>
                          <Transition
                            in={this.state.validFiles && this.state.square}
                            unmountOnExit
                            onExited={this._handleToLine}
                            timeout={duration}
                          >
                            {(state) => (
                              <div className={`fade fade-${state}`} onClick={this._handleSquare}>
                                <SquaredPortrait state={this.state} />
                              </div>
                            )}
                          </Transition>
                          <Transition
                            in={this.state.validFiles && this.state.line}
                            onExited={this._handleToDotted}
                            unmountOnExit
                            timeout={duration}
                          >
                            {(state) => (
                              <div className={`fade fade-${state}`} onClick={this._handleLine}>
                                <LinePortrait state={this.state} />
                              </div>
                            )}
                          </Transition>
                          <div className="loadingContainer">
                            <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                              <g transform="rotate(0 50 50)">
                                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                                  <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.9166666666666666s"
                                    repeatCount="indefinite"
                                  ></animate>
                                </rect>
                              </g>
                              <g transform="rotate(30 50 50)">
                                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                                  <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.8333333333333334s"
                                    repeatCount="indefinite"
                                  ></animate>
                                </rect>
                              </g>
                              <g transform="rotate(60 50 50)">
                                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                                  <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.75s"
                                    repeatCount="indefinite"
                                  ></animate>
                                </rect>
                              </g>
                              <g transform="rotate(90 50 50)">
                                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                                  <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.6666666666666666s"
                                    repeatCount="indefinite"
                                  ></animate>
                                </rect>
                              </g>
                              <g transform="rotate(120 50 50)">
                                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                                  <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.5833333333333334s"
                                    repeatCount="indefinite"
                                  ></animate>
                                </rect>
                              </g>
                              <g transform="rotate(150 50 50)">
                                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                                  <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.5s"
                                    repeatCount="indefinite"
                                  ></animate>
                                </rect>
                              </g>
                              <g transform="rotate(180 50 50)">
                                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                                  <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.4166666666666667s"
                                    repeatCount="indefinite"
                                  ></animate>
                                </rect>
                              </g>
                              <g transform="rotate(210 50 50)">
                                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                                  <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.3333333333333333s"
                                    repeatCount="indefinite"
                                  ></animate>
                                </rect>
                              </g>
                              <g transform="rotate(240 50 50)">
                                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                                  <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.25s"
                                    repeatCount="indefinite"
                                  ></animate>
                                </rect>
                              </g>
                              <g transform="rotate(270 50 50)">
                                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                                  <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.16666666666666666s"
                                    repeatCount="indefinite"
                                  ></animate>
                                </rect>
                              </g>
                              <g transform="rotate(300 50 50)">
                                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                                  <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.08333333333333333s"
                                    repeatCount="indefinite"
                                  ></animate>
                                </rect>
                              </g>
                              <g transform="rotate(330 50 50)">
                                <rect x="48" y="21" rx="0" ry="0" width="4" height="14" fill="#000000">
                                  <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="0s"
                                    repeatCount="indefinite"
                                  ></animate>
                                </rect>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div className="infoContainer">
                          <div className="row">
                            <div className="titleRow">
                              <div className="left">
                                <p>Your Data Portrait.</p>
                              </div>
                              <div className="right">
                                <p>@hello_shiv</p>
                                <p>{timestamp}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="left">
                              <p>What's Data Privacy?</p>
                            </div>
                            <div className="right">
                              <p>
                                <i>Data Privacy</i> relates to how a piece of information&mdash;or data&mdash;should be
                                handled based on its relative importance. For instance, you likely wouldn’t mind sharing
                                your name with a stranger in the process of introducing yourself, but there’s other
                                information you wouldn’t share, like your bank account information or passwords.
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="left">
                              <p>Take Action:</p>
                            </div>
                            <div className="right">
                              <p>
                                Feeling helpless and overwhelmed with a complex topic like Data Privacy can be easy, but
                                don’t get complacent. Privacy isn’t a switch you can just turn on or off&mdash;it’s an
                                ongoing battle that you’ll need to be always mindful of and demand change for. <br />{" "}
                                <br /> Begin here: <u>data-aeternum.com/</u>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Transition>
          </div>
        </div>
      </div>
    );
  }
}

export default Portrait;
