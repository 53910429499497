import React from "react";

import IconItem from "./iconItem";

function actionItem(props) {
  const iconItems = props.item.apps.map(icon => <IconItem key={icon.name} icon={icon} />);
  return (
    <div className="action">
      <div className="exp">
        <div className="checkbox">
          <form>
            <div>
              <input
                type="checkbox"
                checked={props.item.completed}
                onChange={() => {
                  props.handleChange(props.item.id);
                }}
              />
              <label
                onClick={() => {
                  props.handleChange(props.item.id);
                }}
              >
                <span></span>
                <p>{props.item.title}</p>
              </label>
              <div className="actionInfo">
                <div className="desc" dangerouslySetInnerHTML={{ __html: props.item.desc }}></div>
                <div className="icons">{iconItems}</div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default actionItem;
