import React, { Component } from "react";
import Sketch from "react-p5";

//import * as Paint from "../assets/scripts/paint";

var img_data, fileCount;

var img;
var vScale = 1;

class LinePortrait extends Component {
  getImageURI = props => {
    img_data = this.props.state.imageURI;
    fileCount = this.props.state.fileCount;
  };

  preload = p5 => {
    this.getImageURI();
    img = p5.loadImage(img_data);
  };

  setup = (p5, canvasParentRef) => {
    p5.createCanvas(500, 500).parent(canvasParentRef);
    p5.frameRate(1);
    p5.noStroke();
    p5.pixelDensity(3);
    img.resize(p5.width, 0);
    p5.background(200);
    p5.rectMode(p5.CENTER);
  };

  draw = p5 => {
    p5.background(200);
    var angle = 6.0;
    var offsetX = p5.width / 2;
    var offsetY = p5.height / 2;
    var scalar = 3;
    var speed = 0.01;
    var dotcol = 255;
    let brushSize = 1;
    img.loadPixels();

    for (var i = 0; i <= fileCount; i++) {
      var x = offsetX + Math.cos(angle) * scalar;
      var y = offsetY + Math.sin(angle) * scalar;

      var px = Math.floor(x / vScale);
      var py = Math.floor(y / vScale);
      var col = img.get(px, py);

      var rgb = col[0] + col[1] + col[2];

      brushSize = p5.map(rgb, 0, 765, 7, 0);

      p5.fill(0);
      p5.ellipse(x, y, brushSize, brushSize);

      angle += 2 / scalar;
      scalar += 2 / scalar;
    }
  };

  render() {
    return <Sketch setup={this.setup} preload={this.preload} draw={this.draw} keyReleased={this.keyReleased} />;
  }
}

export default LinePortrait;
