import React, { Component } from "react";
import SimpleStorage from "react-simple-storage";
import confetti from "canvas-confetti";

import todosData from "../todosData";
import ActionItem from "./actionItem";

function fireworks() {
  var duration = 5 * 1000;
  var animationEnd = Date.now() + duration;
  var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  var interval = setInterval(function () {
    var timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    var particleCount = 50 * (timeLeft / duration);
    // since particles fall down, start a bit higher than random
    confetti(
      Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } })
    );
    confetti(
      Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } })
    );
  }, 250);

  confetti();
}

class How extends Component {
  constructor() {
    super();
    this.state = {
      todos: todosData,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var canvas = document.getElementById("confettiCanvas");
    // you should  only initialize a canvas once, so save this function
    // we'll save it to the canvas itself for the purpose of this demo
    canvas.confetti = canvas.confetti || confetti.create(canvas, { resize: true });
    canvas.confetti({
      resize: true,
    });
  }

  handleChange(id) {
    //Set State
    this.setState(
      (prevState) => {
        const updatedTodos = prevState.todos.map((todo) => {
          if (todo.id === id) {
            return {
              ...todo,
              completed: !todo.completed,
            };
          }
          return todo;
        });
        return {
          todos: updatedTodos,
        };
      },
      () => {
        //Check if all Actions are completed
        let truths = this.state.todos.filter(function (e) {
          return e.completed === false;
        });
        if (truths.length < 1) {
          fireworks();
        }
      }
    );
  }

  render(props) {
    //console.log(this.state);
    // clearStorage();
    const todoItems = this.state.todos.map((item) => (
      <ActionItem key={item.id} item={item} handleChange={this.handleChange} />
    ));
    return (
      <div className="item" id="how">
        <SimpleStorage parent={this} />
        <div className="inner">
          <div className="col chapter">
            <div className="title">How</div>
            <div className="number">
              Pt
              <br />
              04.
            </div>
          </div>
          <div className="col">
            <p className="question">So what can I do?</p>
            <p>
              Feeling helpless and overwhelmed with a complex topic like Data Privacy can be easy, but don’t get
              complacent. Privacy isn’t a switch you can just turn on or off — it’s an ongoing battle that you’ll need
              to be always mindful of, keep track of, and demand change for.
            </p>
            <p>
              In the meantime, however, take your first steps to a stronger data privacy by reducing your data
              footprint, i.e. the amount of data you’re giving away willingly and unwillingly. It's not comprehensive,
              but it'll give you a easy nudge into starting to take your privacy more seriously.
            </p>
          </div>
          <div className="col actionItems">
            {/* To Do Item */}
            {todoItems}
            {/* End To Do Item */}
          </div>
        </div>
        <canvas id="confettiCanvas"></canvas>
      </div>
    );
  }
}

export default How;
